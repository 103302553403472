@font-face {
  font-family: "title";
  src: url("../../../fonts/DIN2014-Bold.woff2") format("woff2"), url("../../../fonts/DIN2014-Bold.woff") format("woff"), url("../../../fonts/DIN2014-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text";
  src: url("../../../fonts/DIN2014-Regular.woff2") format("woff2"), url("../../../fonts/DIN2014-Regular.woff") format("woff"), url("../../../fonts/DIN2014-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "font-link";
  src: url("../../../fonts/DIN2014-DemiBold.woff2") format("woff2"), url("../../../fonts/DIN2014-DemiBold.woff") format("woff"), url("../../../fonts/DIN2014-DemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.slide {
  width: 100%;
}

.view::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.container-slide {
  height: 100%;
  position: absolute;
  width: 45%;
  top: 0;
  left: 5%;
}

.container-slide .caption-slide {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 0 15px;
  transform: translateY(-50%);
}

.container-slide .caption-slide strong.head-title {
  color: white;
  font-size: 1.2rem;
}

.container-slide .caption-slide h1.title {
  color: white;
  font-size: 3rem;
}

.carousel-indicators li {
  border-radius: 50% !important;
  background-color: #ffffff;
  width: 6px !important;
  height: 6px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.carousel-control-prev i,
.carousel-control-next i {
  font-size: 35px;
}

.provide {
  width: 100%;
  background-color: #F9F9F9;
}

section.product-module .product {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #efefef;
}

section.product-module .product .image.icon {
  max-width: 130px;
}

section.product-module .product .title h3 {
  font-size: 1.2rem;
}

section.product-module .product .text p {
  color: #212529;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide.client-logo {
  border-radius: 5px;
  border: 1px solid #efefef;
}

@media only screen and (max-width: 992px) {
  .home-content .t-center {
    text-align: center !important;
  }

  .home-content .content-center {
    width: 100% !important;
    max-width: 530px !important;
    margin: auto !important;
  }

  .home-content .content-center .head p {
    margin-top: 20px !important;
  }

  .home-content .old-home {
    display: none !important;
  }

  .home-content .new-home {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .btn-slide {
    padding-top: 0% !important;
  }

  .btn-slide a.link-btn {
    font-size: 18px !important;
    background-color: unset !important;
  }

  .btn-slide a.link-btn:hover {
    color: #BF943A !important;
    box-shadow: unset !important;
  }
}

@media only screen and (max-width: 500px) {
  .btn-slide a.link-btn {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 410px) {
  .carousel-indicators {
    display: none !important;
  }
}

